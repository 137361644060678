import React, { useEffect, useState } from 'react';
import HeaderTwo from '../Header/HeaderTwo';
import FooterTwo from '../Footer/FooterThree';
import Banner from '../Banner/Banner';
import About from '../About/About';
import Service from '../HomeTwo/HomeComponent';
import Choose from '../Choose/Choose';
import Blog from '../Blog/blogCarousel'; // BlogCarousel component
import ArticleDetail from '../Blog/articleDetails'; // ArticleDetail component
import Pricing from '../Pricing/Pricing';
import Process from '../Choose/Process';
import './HomeTwo.css'; // Importing the CSS file

const HomeOne = () => {
    const [selectedArticle, setSelectedArticle] = useState(null); // State for modal article
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility

    useEffect(() => {
        const sections = document.querySelectorAll('.section');
    
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1 // Adjust the threshold for better triggering on smaller screens
        });
    
        sections.forEach(section => {
            observer.observe(section);
        });
    
        return () => {
            sections.forEach(section => {
                observer.unobserve(section);
            });
        };
    }, []);

    const openModal = (article) => {
        setSelectedArticle(article); // Set the selected article to be displayed in the modal
        setIsModalOpen(true); // Open the modal
    };

    const closeModal = () => {
        setSelectedArticle(null); // Clear the selected article when closing
        setIsModalOpen(false); // Close the modal
    };

    document.title = "JDIT Home"; // Set the page title
    
    return (
        <div className="home__two">
            <div className="header-banner">
                <HeaderTwo />
                <Banner />
            </div>
            
            <div className="section">
                <About />
            </div>
            <div className="section">
                <Service />
            </div>
            <div className="section">
                <Choose />
            </div>
            <div className="section">
                <Process />
            </div> 
            <div className="section">
                <Blog openModal={openModal} /> {/* Pass modal control to BlogCarousel */}
            </div>
            <FooterTwo />

            {/* Modal rendering */}
            {isModalOpen && (
                <div style={modalStyles.modalOverlay}>
                    <div style={modalStyles.modalContent}>
                        <button style={modalStyles.closeButton} onClick={closeModal}>Close</button>
                        <ArticleDetail article={selectedArticle} /> {/* Pass selected article to ArticleDetail */}
                    </div>
                </div>
            )}
        </div>
    );
};

// Modal Styles
const modalStyles = {
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000 // Ensure modal is on top
    },
    modalContent: {
        backgroundColor: '#ffffff',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#ff0000',
        color: '#ffffff',
        border: 'none',
        padding: '5px 10px',
        borderRadius: '5px',
        cursor: 'pointer'
    }
};

export default HomeOne;
