import React, { useState } from 'react';
import Header from '../Header/HeaderTwo'; // Assuming the Header component is in the same directory

const Locations = () => {
  const towns = [
    'Charlotte', 'Raleigh', 'Greensboro', 'Durham', 'Winston-Salem', 'Fayetteville', 'Cary', 'Wilmington',
    'High Point', 'Asheville', 'Greenville', 'Concord', 'Gastonia', 'Jacksonville', 'Chapel Hill', 'Roxboro',
    'Hickory', 'Kannapolis', 'Goldsboro', 'Rocky Mount', 'Sanford', 'Lumberton', 'Garner', 'Statesville', 'Boone',
  ];

  const [currentTownIndex, setCurrentTownIndex] = useState(0);

  const handleCityClick = (index) => {
    setCurrentTownIndex(index);
    window.scrollTo({ top: 200, behavior: 'smooth' }); // Scroll to content when city is selected
  };

  return (
    <>
      <Header />

      {/* Main Content */}
      <div
        style={{
          padding: '160px 20px 80px', // Increased padding to ensure the content is clearly below the header
          textAlign: 'center',
          background: 'linear-gradient(to bottom right, #003366, #001f4d)',
          color: '#ffffff',
          minHeight: '100vh',
        }}
      >
        {/* Header for Selected Town */}
        <h1
          style={{
            fontSize: '3em',
            fontWeight: 'bold',
            color: '#ffffff',
            marginBottom: '40px',
          }}
        >
          IT Services for {towns[currentTownIndex]}
        </h1>

        {/* List of Cities as Buttons */}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '20px', // Ensure consistent spacing between buttons
            marginBottom: '40px',
          }}
        >
          {towns.map((town, index) => (
            <button
              key={town}
              onClick={() => handleCityClick(index)}
              style={{
                padding: '10px 15px',
                backgroundColor: currentTownIndex === index ? '#ff9800' : '#ffffff',
                color: currentTownIndex === index ? '#ffffff' : '#003366',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: currentTownIndex === index ? 'bold' : 'normal',
                transition: 'background-color 0.3s, color 0.3s, transform 0.2s',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.05)';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'scale(1)';
              }}
            >
              {town}
            </button>
          ))}
        </div>

        {/* Section for Detailed Content */}
        <div
          style={{
            fontSize: '1.2em',
            maxWidth: '800px',
            margin: '0 auto',
            color: '#f0f0f0',
            transition: 'opacity 1s ease-in-out',
            opacity: 1,
          }}
        >
          <p style={{ marginBottom: '30px', lineHeight: '1.8' }}>
            At Just Do I.T., we provide professional IT services across all towns and counties in North Carolina, including{' '}
            <strong>{towns[currentTownIndex]}</strong>. Our expert team is dedicated to offering top-notch IT solutions, including managed IT services, cybersecurity, Microsoft 365 setup, cloud services, and more. We understand that every business in {towns[currentTownIndex]} is unique, and we tailor our services to meet those specific needs.
          </p>

          <h2 style={{ color: '#ffffff', marginTop: '60px', marginBottom: '20px', fontSize: '2.5em' }}>
            Why Choose Our IT Services in {towns[currentTownIndex]}?
          </h2>
          <ul style={{ textAlign: 'left', margin: '20px auto', maxWidth: '600px', lineHeight: '1.8', color: '#f0f0f0' }}>
            <li>Comprehensive Managed IT Services tailored for local businesses in {towns[currentTownIndex]}.</li>
            <li>Rapid response times and local expertise, ensuring your {towns[currentTownIndex]} business gets the support it needs when it needs it.</li>
            <li>Advanced cybersecurity measures to protect your business and data from cyber threats.</li>
            <li>Friendly, experienced technicians who understand the local landscape and the challenges faced by businesses in {towns[currentTownIndex]}.</li>
          </ul>

          <h3 style={{ marginTop: '60px', color: '#ffffff' }}>Local Expertise in {towns[currentTownIndex]}</h3>
          <p style={{ marginBottom: '30px', lineHeight: '1.8' }}>
            As a local IT provider, we are proud to support businesses in {towns[currentTownIndex]} with a full range of IT services designed to boost efficiency, enhance security, and drive growth. Our technicians are well-versed in the specific needs of {towns[currentTownIndex]}, and we are committed to helping your business thrive in the competitive market.
          </p>

          
        </div>
      </div>
    </>
  );
};

export default Locations;
