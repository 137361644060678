import React, { useEffect } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaLock, FaMapSigns, FaEye, FaWrench, FaFlag } from 'react-icons/fa';
import './CybersecurityTimeline.css'; // Importing the CSS file

const CybersecurityTimeline = () => {
  useEffect(() => {
    const elements = document.querySelectorAll('.fade-in');

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-visible');
          observer.unobserve(entry.target);
        }
      });
    });

    elements.forEach(element => observer.observe(element));

    return () => elements.forEach(element => observer.unobserve(element));
  }, []);

  return (
    <>

    <h2 style={{textAlign: 'center', paddingBottom: '20px'}}>Our MSSP Onboarding Process</h2>
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work fade-in"
        contentStyle={{ background: '#007bff', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid  #007bff' }}
        date="Step 1"
        iconStyle={{ background: '#007bff', color: '#fff' }}
        icon={<FaLock />}
      >
        <h3 className="vertical-timeline-element-title">Phase 1: Lock It Down!</h3>
        <p>Install Cybersecurity & RMM Tools to understand cyber risks and support current fixes.</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work fade-in"
        contentStyle={{ background: '#28a745', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid  #28a745' }}
        date="Step 2"
        iconStyle={{ background: '#28a745', color: '#fff' }}
        icon={<FaMapSigns />}
      >
        <h3 className="vertical-timeline-element-title">Phase 2: Know the Lay of the Land</h3>
        <p>Understand the customer’s network and business processes to develop a tailored plan.</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work fade-in"
        contentStyle={{ background: '#ffc107', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid  #ffc107' }}
        date="Step 3"
        iconStyle={{ background: '#ffc107', color: '#fff' }}
        icon={<FaEye />}
      >
        <h3 className="vertical-timeline-element-title">Phase 3: Keep a Watchful Eye</h3>
        <p>Develop a Continuous Monitoring & Sustainment Plan to maintain security and efficiency.</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work fade-in"
        contentStyle={{ background: '#17a2b8', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid  #17a2b8' }}
        date="Step 4"
        iconStyle={{ background: '#17a2b8', color: '#fff' }}
        icon={<FaWrench />}
      >
        <h3 className="vertical-timeline-element-title">Phase 4: Fine-Tune for Success</h3>
        <p>Review & optimize business processes for improved efficiency.</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work fade-in"
        contentStyle={{ background: '#6f42c1', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid  #6f42c1' }}
        date="Step 5"
        iconStyle={{ background: '#6f42c1', color: '#fff' }}
        icon={<FaFlag />}
      >
        <h3 className="vertical-timeline-element-title">Phase 5: Future-Proof Your Business</h3>
        <p>Develop a Tech Roadmap for long-term success and growth.</p>
      </VerticalTimelineElement>
    </VerticalTimeline>
    </>
  );
};

export default CybersecurityTimeline;
