import React, { useState } from 'react';

const LeadForm = () => {
  // State for form data
  const [formData, setFormData] = useState({
    name: '',
    businessName: '',
    email: '',
    phone: '',
  });

  // State to track form submission
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Split the name into first and last names
    const pieces = formData.name.split(' ');
    const lastName = pieces.slice(1).join(' ');

    // Create the data object to send
    const data = {
      first_name: pieces[0],
      last_name: lastName,
      email: formData.email,
      phone: formData.phone,
      business_name: formData.businessName,
    };

    // Send data to the backend API
    fetch(
      'https://justdoitservices.syncromsp.com/api/v1/leads?api_key=Tae84ddd0cff82be12-a80351ed91479e8c8c37f7baf4440c85',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(data),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        setFormSubmitted(true); // Set form submitted to show Thank You message and download button
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div
      style={{
        background: '#ffffff',
        padding: '30px',
        borderRadius: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        color: '#003366',
        maxWidth: '400px',
        width: '100%',
        margin: '0 auto',
      }}
    >
      {!formSubmitted ? (
        <>
          <h2
            style={{
              fontSize: '1.8em',
              color: '#003366',
              textAlign: 'center',
              marginBottom: '20px',
            }}
          >
            Get access to our service tiers explained document.
          </h2>
          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '20px' }}>
              <label
                htmlFor="name"
                style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px' }}
              >
                Name:
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                style={{
                  width: '100%',
                  padding: '12px',
                  borderRadius: '8px',
                  border: '1px solid #ccc',
                  background: '#f0f0f0',
                  fontSize: '1em',
                }}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <label
                htmlFor="businessName"
                style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px' }}
              >
                Business Name:
              </label>
              <input
                type="text"
                name="businessName"
                value={formData.businessName}
                onChange={handleInputChange}
                required
                style={{
                  width: '100%',
                  padding: '12px',
                  borderRadius: '8px',
                  border: '1px solid #ccc',
                  background: '#f0f0f0',
                  fontSize: '1em',
                }}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <label
                htmlFor="email"
                style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px' }}
              >
                Email:
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                style={{
                  width: '100%',
                  padding: '12px',
                  borderRadius: '8px',
                  border: '1px solid #ccc',
                  background: '#f0f0f0',
                  fontSize: '1em',
                }}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <label
                htmlFor="phone"
                style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px' }}
              >
                Phone Number:
              </label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
                style={{
                  width: '100%',
                  padding: '12px',
                  borderRadius: '8px',
                  border: '1px solid #ccc',
                  background: '#f0f0f0',
                  fontSize: '1em',
                }}
              />
            </div>
            <button
              type="submit"
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#ff9800',
                color: '#ffffff',
                border: 'none',
                borderRadius: '10px',
                cursor: 'pointer',
                fontWeight: 'bold',
                fontSize: '1.1em',
                transition: 'background-color 0.3s ease',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#e68a00')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#ff9800')}
            >
              Submit
            </button>
          </form>
        </>
      ) : (
        <>
          <h2
            style={{
              fontSize: '1.8em',
              color: '#003366',
              textAlign: 'center',
              marginBottom: '20px',
            }}
          >
            Thank You!
          </h2>
          <p style={{ textAlign: 'center', marginBottom: '30px', lineHeight: '1.5' }}>
            You can now download the document using the link below.
          </p>
          <a
            href="https://justdoitfiles.s3.amazonaws.com/Service+Tiers+.pdf"
            download
            style={{
              display: 'block',
              textAlign: 'center',
              padding: '15px',
              backgroundColor: '#28a745',
              color: '#ffffff',
              borderRadius: '10px',
              textDecoration: 'none',
              fontWeight: 'bold',
              marginBottom: '20px',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = '#218838')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = '#28a745')}
          >
            Download Document
          </a>
        </>
      )}
    </div>
  );
};

export default LeadForm;
