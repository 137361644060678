import React from 'react';
import { Link } from 'react-router-dom';

const ServicesOverview = () => {
  return (
    <div
      style={{
        background: 'transparent',
        padding: '60px 20px',
        color: '#003366',
      }}
    >
      <h2 style={{ color: '#ffffff', textAlign: 'center', marginBottom: '50px', fontSize: '2em' }}>
        Our Services
      </h2>

      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '30px' }}>
        {[
          { title: 'Help Desk Support', description: 'Fast and efficient support to ensure your employees stay productive. Stop wasting valuable time on IT issues—let our experts handle it.' },
          { title: 'Cybersecurity', description: 'Protect your business from cyber threats with our comprehensive cybersecurity solutions, tailored to safeguard your data and operations.' },
          { title: 'Microsoft 365', description: 'Get the most out of your Microsoft 365 subscription with expert setup, training, and support to maximize productivity for your business.' },
          { title: 'Cloud Services', description: 'Move your business to the cloud with ease. Enhance collaboration and access your data securely from anywhere.' },
          { title: 'Backup and Disaster Recovery', description: 'Ensure your data is safe and recoverable in case of an emergency with our robust backup and disaster recovery solutions.' },
          { title: 'IT Strategy and Professional Services', description: 'Need help planning your IT projects? Our professional services team can assist with everything from new office setups to choosing the right technology partners.' },
        ].map((service, index) => (
          <div
            key={index}
            style={{
              background: '#ffffff',
              borderRadius: '15px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '30px',
              width: 'calc(30% - 40px)',
              minWidth: '300px',
              textAlign: 'center',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
            }}
          >
            <h3 style={{ color: '#003366', marginBottom: '20px', fontSize: '1.5em' }}>{service.title}</h3>
            <p style={{ lineHeight: '1.6', color: '#666' }}>{service.description}</p>
          </div>
        ))}
      </div>

      <div style={{ textAlign: 'center', marginTop: '50px', fontSize: '1.1em' }}>
        <p style={{ color: '#666' }}>
          If you want to know more about the specifics of our services,{' '}
          <Link
            to="/services"
            style={{
              color: '#ff9800',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            click here
          </Link>.
        </p>
      </div>
    </div>
  );
};

export default ServicesOverview;
