import React from 'react';
import ServicesOverview from '../GeneralServicesHomepage/generalServices'; // Assuming ServicesOverview is imported
import LeadForm from '../Form/HomepageForm'; // Assuming LeadForm is imported

const HomeSection = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          background: 'linear-gradient(to bottom, #003366, #ffffff)',
          padding: '60px 20px',
          minHeight: '100vh', // Ensures the full height of the screen is used
          position: 'relative',
          flexDirection: window.innerWidth <= 768 ? 'column' : 'row', // Stacks components vertically on mobile
        }}
      >
        {/* Services Overview Section */}
        <div
          style={{
            flex: window.innerWidth <= 768 ? '0 1 100%' : '0 1 70%',
            paddingRight: window.innerWidth <= 768 ? '0' : '20px',
            paddingBottom: window.innerWidth <= 768 ? '20px' : '0',
            boxSizing: 'border-box',
          }}
        >
          <ServicesOverview />
        </div>
  
        {/* Lead Form Section */}
        <div
          style={{
            flex: window.innerWidth <= 768 ? '0 1 100%' : '0 1 30%',
            display: 'flex',
            alignItems: 'center', // Center vertically on desktop
            justifyContent: 'center',
            boxSizing: 'border-box',
            paddingLeft: window.innerWidth <= 768 ? '0' : '20px',
          }}
        >
          <div
            style={{
              width: window.innerWidth <= 768 ? '80vw' : '100%', // 80% of viewport width on mobile
              margin: window.innerWidth <= 768 ? '0 auto' : 'unset', // Center form on mobile
            }}
          >
            <LeadForm />
          </div>
        </div>
      </div>
    );
  };
  
  export default HomeSection;