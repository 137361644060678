import React, { useState, useEffect } from "react";
import articles from "./articles"; // Adjust the path as necessary

const BlogCarousel = ({ openModal }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Check for window resizing to adjust the text size and layout dynamically
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
    style={{
      display: 'flex',
      gap: '10px',
      padding: '10px',
      overflowX: 'auto',
      
      flexWrap: 'nowrap',
    }}
    >
  {articles.map((article) => (
    <div
    key={article.id}
    style={{
      flex: isMobile ? '0 0 90%' : '0 0 20%', // Adjust width for mobile and desktop
      background: 'linear-gradient(219deg, #003366 0%, #1E90FF 100%)',
      padding: '20px',
      borderRadius: '20px',
      display: 'inline-block',
      boxSizing: 'border-box',
      transition: 'transform 0.3s ease-in-out',
      overflow: 'hidden', // Ensure content doesn't overflow
      wordWrap: 'break-word', // Enable word wrapping
      wordBreak: 'break-word', // Break long words
      maxWidth: '90%', // Limit max width to 90% on mobile, 20% on desktop
    }}
    >
      {/* Adjust font sizes for mobile vs desktop */}
      <h3 style={{ fontSize: isMobile ? '16px' : '24px', marginBottom: '10px' }}>
        {article.title}
      </h3>
      <p
        style={{
          fontSize: isMobile ? '12px' : '16px',
          lineHeight: '1.4',
          whiteSpace: 'normal', // Allow text to wrap normally
        }}
      >
        {article.summary}
      </p>
      {/* Button to open modal with article details */}
      <button
        onClick={() => openModal(article)} // Open modal with article details
        style={{
          fontSize: isMobile ? '12px' : '14px',
          backgroundColor: '#1E90FF',
          color: '#ffffff',
          padding: '10px',
          border: 'none',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
            >
              Read More
            </button>
          </div>
        ))}
      </div>
  );
};

export default BlogCarousel;
