import React from 'react';
import shape4 from "../../assets/images/shapes/price-bg.png";

const Pricing = () => {
    const pricingData = [
      {
        id: 1,
        price_name: 'Endpoint',
        list_a: 'Patch Management',
        list_b: 'Threat Prevention & Detection',
        list_c: 'Realtime Monitoring & Alerts',
        list_d: 'Remote Remediation & Support',
        list_e: 'Reporting & Compliance',
        amount: '40',
        time: 'device',
        delay: '100ms',
      },
      {
        id: 2,
        price_name: 'Network',
        list_a: 'Network Monitoring & Alerts',
        list_b: 'Device Management & Configuration',
        list_c: 'Bandwidth & Traffic Analysis',
        list_d: 'Network Security Assessments',
        list_e: 'Reporting & Compliance Management',
        amount: '200',
        time: 'm',
        delay: '200ms',
      },
      {
        id: 3,
        price_name: 'Complete',
        list_a: 'Endpoint Benefits',
        list_b: 'Network Benefits',
        list_c: '100 Hours Of IT Support',
        list_d: 'Total Based On Number Of Endpoints',
        list_e: 'Bulk Discounts Avaliable',
        amount: '150',
        time: '+d',
        delay: '300ms',
      },
      {
        id: 4,
        price_name: 'Add-Ons',
        list_a: 'Network Design & Implementation',
        list_b: 'AI, Machine Learning, & Custom LLMs',
        list_c: 'Cybersecurity & Business Documentation Update/Creation',
        list_d: 'Additional Hours of Support',
        list_e: 'Website Design & Optimization',
        amount: '',
        time: '',
        delay: '300ms',
      },
    ];

    return (
        <section className="pricing__section see__pad p_relative" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <div className="pattern-layer">
                <div className="pattern-1 p_absolute" data-parallax='{"y": -50}' style={{ backgroundImage: `url(${shape4})`, backgroundPosition: 'center center', backgroundSize: 'cover' }}></div>
            </div>
            <div className="auto-container">
                <div className="title__data" style={{ textAlign: 'center' }}>
                    <div className="sub__title">
                        <h4>Pricing</h4>
                    </div>
                    <div className="title">
                        <h2>Product Price</h2>
                    </div>
                </div>
                <div className="row" style={{ justifyContent: 'center' }}>
                    {pricingData.map((item) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay={item.delay} data-wow-duration="1500ms" key={item.id} style={{ paddingBottom: 40 }}>
                            <div className="price__content p_relative" style={{ textAlign: 'center' }}>
                                <div className="price__data">
                                    <h5>{item.price_name}</h5>
                                    <h4>
                                        {item.id !== 4 && <span>$</span>}
                                        {item.amount} 
                                        {item.amount && item.time ? ' / ' : ''}
                                        <span>{item.time}</span>
                                    </h4>
                                </div>
                                <div className="price__block">
                                    <div className="price__list">
                                        <ul>
                                            <li>{item.list_a}</li>
                                            <li>{item.list_b}</li>
                                            <li>{item.list_c}</li>
                                            <li>{item.list_d}</li>
                                            <li>{item.list_e}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="price__icon">
                                    <i className="icon-16"></i>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Pricing;
