import React, { useState, useEffect, memo } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import shape4 from "../../assets/images/shapes/shape-06.png";
import { Link } from 'react-router-dom';

// Component for rotating text only
const RotatingText = memo(() => {
  const [primaryWord, setPrimaryWord] = useState('Built-In');
  const [secondaryWord, setSecondaryWord] = useState('Bolted-On');
  const [fadeClass, setFadeClass] = useState('fade-in');

  useEffect(() => {
    const words = [
      { primary: 'Built-In', secondary: 'Bolted-On' },
      { primary: 'Proactive', secondary: 'Reactive' }
    ];
    let index = 0;

    const intervalId = setInterval(() => {
      // Trigger fade-out
      setFadeClass('fade-out');

      setTimeout(() => {
        // Change the words after fade-out
        index = (index + 1) % words.length;
        setPrimaryWord(words[index].primary);
        setSecondaryWord(words[index].secondary);

        // Trigger fade-in after changing words
        setFadeClass('fade-in');
      }, 500); // Matches the transition duration

    }, 3000); // Change every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  // Define inline styles for the fading effect
  const fadeStyle = {
    opacity: fadeClass === 'fade-in' ? 1 : 0,
    transition: 'opacity 0.5s ease-in-out'
  };

  return (
    <h2 className="p_relative">
      {/* Apply fade effect but keep existing text styles */}
      <span className="slider-text-anim" style={fadeStyle}>{primaryWord}</span>{' '}
      <span
        className="slider-text-anim title__last"
        style={{
          ...fadeStyle,
          textDecoration: 'line-through',
          textDecorationColor: 'red',
          textDecorationThickness: '4px',
        }}
      >
        <span>{secondaryWord}</span>
      </span>
    </h2>
  );
});

const Banner = () => {
  return (
    <section className="banner__two slider-two p_relative">
      <div className="pattern-layer">
        <div
          className="pattern-1 p_absolute"
          data-parallax='{"x": 100}'
          style={{ backgroundImage: `url(${shape4})` }}
        ></div>
      </div>
      <div className="image-layer p_absolute" style={{ background: '#003366' }}></div>
      <div className="banner__data__two p_relative">
        <OwlCarousel
          className="banner-carousel owl-theme owl-carousel owl-dots-none"
          items={1}
          loop={false}
          margin={0}
          smartSpeed={1000}
          autoplay={6000}
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 1,
            },
            768: {
              items: 1,
            },
            992: {
              items: 1,
            },
            1200: {
              items: 1,
            },
          }}
        >
          <div className="slide-item p_relative">
            <div className="auto-container">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="content-box p_relative">
                    <div className="sub__title">
                      <h4 className="p_relative">
                        <span className="slider-text-anim two">Cybersecurity</span>
                      </h4>
                    </div>
                    {/* Only the rotating text is rendered dynamically */}
                    <RotatingText />
                    <p>
                    We make cybersecurity a natural part of your business, keeping things safe and compliant without getting in the way of your day-to-day operations.                    </p>
                    <div className="btn-box">
                      <Link to="/contact" className="theme-btn theme-btn-one">
                        <i className="icon-02"></i> Contact Us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
        <div className="banner__bottom">
          <div className="row">
            <div className="col-lg-6 col-md-12"></div>
            <div className="col-lg-6 col-md-12">
              <div className="banner__mission__block">
                <div className="mission__block" style={{padding: '10px'}}>
                  <h3>Our Vision</h3>
                  <p>
                    Empowering small and medium-sized businesses with the same level of cybersecurity protections as large enterprises.
                  </p>
                </div>
                <div className="mission__block" style={{padding: '10px'}}>
                  <h3>Our Mission</h3>
                  <p>
                  Delivering affordable cybersecurity, we don't just identify problems—we build lasting partnerships. We proactively monitor your network, identify issues early, and implement tailored solutions to prevent disruptions, ensuring your business stays secure.                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
