import React, { useState, useEffect } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb from '../BreadCrumb';
import HeaderOne from '../Header/HeaderTwo';
import FooterOne from '../Footer/FooterThree';
import { Link, useNavigate, useBeforeUnload } from 'react-router-dom';
import ServiceModal from '../Services/ServiceModal';
import Modal from 'react-modal';

// Custom styling for the exit intent modal
const customStyles = {
  content: {
    maxWidth: '600px',
    margin: 'auto',
    padding: '40px',
    borderRadius: '15px',
    background: '#003366',
    color: '#ffffff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    zIndex: 1000,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
};

const Services = () => {
  pageTitle('Services');
  const navigate = useNavigate();

  const [selectedService, setSelectedService] = useState(null);
  const [leaveModalOpen, setLeaveModalOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    businessName: '',
  });
  const [allowModal, setAllowModal] = useState(false);

  const servicesData = [
    {
      id: 1,
      title: 'Managed IT Services',
      description: 'Comprehensive IT support tailored for small to medium-sized businesses. We take care of your IT infrastructure so you can focus on your business.',
      icon: 'icon-04', // old icon
      href: '/services/managedit',
      data_aos_delay: '00ms',
      keyBenefits: [
        '24/7 monitoring and support',
        'Proactive maintenance and updates',
        'On-site and remote IT assistance',
        'Customizable support plans',
        'Increased efficiency and productivity',
      ],
    },
    {
      id: 2,
      title: 'Cybersecurity Solutions',
      description: 'Protect your business from evolving cyber threats with our tailored cybersecurity services. From endpoint security to network monitoring, we have you covered.',
      icon: 'icon-08', // old icon
      href: '/services/cybersecurity',
      data_aos_delay: '200ms',
      keyBenefits: [
        'Advanced threat detection and response',
        'Endpoint protection using Bitdefender GravityZone',
        'Compliance with NIST, FEDRAMP, CMMC standards',
        'Regular security assessments',
        'Employee training and awareness',
      ],
    },
    {
      id: 3,
      title: 'Cloud Solutions',
      description: 'Transform your business with secure cloud solutions. We provide Virtual Desktop Infrastructure (VDI), secure cloud storage, and cloud application management.',
      icon: 'icon-12', // old icon
      href: '/services/cloudsolutions',
      data_aos_delay: '400ms',
      keyBenefits: [
        'Scalable cloud infrastructure',
        'Secure data storage and backup',
        'Remote work capabilities',
        'Cloud application support',
        'Cost-effective and flexible solutions',
      ],
    },
    {
      id: 4,
      title: 'Network Design & Implementation',
      description: 'Design, implement, and optimize your network infrastructure to support your business goals. Our experts ensure a reliable and secure network.',
      icon: 'icon-04', // old icon
      href: '/services/networkdesign',
      data_aos_delay: '00ms',
      keyBenefits: [
        'Custom network architecture tailored to your needs',
        'Scalable and secure network infrastructure',
        'Seamless integration with existing systems',
        'Optimized for performance and reliability',
        'Network security assessments and hardening',
      ],
    },
    {
      id: 5,
      title: 'AI, Machine Learning, and LLM Solutions',
      description: 'Integrate AI/ML and LLM technologies to streamline business processes and gain valuable insights. We provide customized AI solutions for your business.',
      icon: 'icon-08', // old icon
      href: '/services/ai-ml-llm',
      data_aos_delay: '200ms',
      keyBenefits: [
        'Custom AI/ML model development',
        'Automation of repetitive business tasks',
        'Data-driven insights and predictive analytics',
        'Natural Language Processing (NLP) with LLMs',
        'Seamless integration with existing workflows',
      ],
    },
    {
      id: 6,
      title: 'Backup and Disaster Recovery',
      description: 'Ensure your data is always safe and recoverable. Our backup and disaster recovery services provide peace of mind in case of emergencies.',
      icon: 'icon-12', // old icon
      href: '/services/backup-recovery',
      data_aos_delay: '400ms',
      keyBenefits: [
        'Automated and secure data backups',
        'Disaster recovery planning and testing',
        'Quick data recovery to minimize downtime',
        'Scalable backup solutions',
        'Compliance with data protection regulations',
      ],
    },
    {
      id: 7,
      title: 'IT Project Management',
      description: 'From planning to execution, we manage IT projects of all sizes to help your business grow and adapt to changing needs.',
      icon: 'icon-12', // old icon
      href: '/services/itprojects',
      data_aos_delay: '400ms',
      keyBenefits: [
        'Comprehensive project planning and management',
        'Infrastructure upgrades and implementation',
        'Timely and budget-friendly delivery',
        'Seamless integration with existing systems',
        'Post-project support and maintenance',
      ],
    },
    {
      id: 8,
      title: 'Compliance and Risk Assessments',
      description: 'Maintain compliance and assess risks with our thorough security audits, policy generation, and compliance reviews tailored to your industry.',
      icon: 'icon-12', // old icon
      href: '/services/compliance-risk',
      data_aos_delay: '400ms',
      keyBenefits: [
        'Customized compliance assessments',
        'Policy creation for industry regulations',
        'Risk assessments and vulnerability scans',
        'Continuous improvement recommendations',
        'Compliance with NIST, CMMC, and other standards',
      ],
    },
  ];
  

  // Function to handle form change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Send data to backend API
    fetch('https://justdoitservices.syncromsp.com/api/v1/leads?api_key=Tae84ddd0cff82be12-a80351ed91479e8c8c37f7baf4440c85', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        setFormSubmitted(true);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      <HeaderOne />
      <div className="service__page p_relative see__pad" style={{ paddingTop: '20vh' }}>
        <div className="auto-container">
          <div className="row">
            {servicesData.map((item, id) => (
              <div
                key={id}
                className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay={item.data_aos_delay}
                data-wow-duration="1500ms"
                onClick={() => setSelectedService(item)}
                style={{
                  background: '#ffffff',
                  borderRadius: '15px',
                  padding: '30px',
                  margin: '20px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  cursor: 'pointer',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
                }}
              >
                <div className="service__icon" style={{ marginBottom: '20px' }}>
                  <i className={item.icon}></i>
                </div>
                <div className="service__text">
                  <h4 style={{ color: '#003366', fontSize: '1.8em', marginBottom: '15px' }}>{item.title}</h4>
                  <p style={{ lineHeight: '1.6', color: '#666' }}>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Service Modal (for Learn More button) */}
      <ServiceModal
        isOpen={selectedService !== null}
        onClose={() => setSelectedService(null)}
        service={selectedService}
      />

      {/* Exit Intent Modal */}
      <Modal
        isOpen={leaveModalOpen}
        onRequestClose={() => setLeaveModalOpen(false)}
        style={customStyles}
        ariaHideApp={false}
      >
        <button
          onClick={() => setLeaveModalOpen(false)}
          style={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            background: 'transparent',
            border: 'none',
            fontSize: '1.5em',
            color: '#ffffff',
            cursor: 'pointer',
          }}
        >
          &times;
        </button>

        {!formSubmitted ? (
          <>
            <h2 style={{ fontSize: '1.8em', color: '#ff9800', textAlign: 'center', marginBottom: '20px' }}>
              Before You Go...
            </h2>
            <p style={{ textAlign: 'center', marginBottom: '30px', lineHeight: '1.5' }}>
              We have a valuable document that includes the full list of our services and tier options. Please fill out the form below to download it.
            </p>
            <form onSubmit={handleFormSubmit}>
              <div style={{ marginBottom: '20px' }}>
                <label htmlFor="name" style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px' }}>
                  Name:
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  style={{
                    width: '100%',
                    padding: '12px',
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    background: '#f0f0f0',
                    fontSize: '1em',
                  }}
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                <label htmlFor="businessName" style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px' }}>
                  Business Name:
                </label>
                <input
                  type="text"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleInputChange}
                  required
                  style={{
                    width: '100%',
                    padding: '12px',
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    background: '#f0f0f0',
                    fontSize: '1em',
                  }}
                />
              </div>
              <div style={{ marginBottom: '20px' }}>
                <label htmlFor="email" style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px' }}>
                  Email:
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  style={{
                    width: '100%',
                    padding: '12px',
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    background: '#f0f0f0',
                    fontSize: '1em',
                  }}
                />
              </div>
              <button
                type="submit"
                style={{
                  width: '100%',
                  padding: '15px',
                  backgroundColor: '#ff9800',
                  color: '#ffffff',
                  border: 'none',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  fontSize: '1.1em',
                  transition: 'background-color 0.3s ease',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#e68a00')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = '#ff9800')}
              >
                Submit
              </button>
            </form>
          </>
        ) : (
          <>
            <h2 style={{ fontSize: '1.8em', color: '#ff9800', textAlign: 'center', marginBottom: '20px' }}>
              Thank You!
            </h2>
            <p style={{ textAlign: 'center', marginBottom: '30px', lineHeight: '1.5' }}>
              You can now download the document using the link below.
            </p>
            <a
              href="https://justdoitfiles.s3.amazonaws.com/ServicesExplained.pdf"
              download
              style={{
                display: 'block',
                textAlign: 'center',
                padding: '15px',
                backgroundColor: '#28a745',
                color: '#ffffff',
                borderRadius: '10px',
                textDecoration: 'none',
                fontWeight: 'bold',
                marginBottom: '20px',
                transition: 'background-color 0.3s ease',
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#218838')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#28a745')}
            >
              Download Document
            </a>
          </>
        )}
      </Modal>

      <FooterOne />
    </>
  );
};

export default Services;