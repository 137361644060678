import { Fragment } from "react";
import { Link } from "react-router-dom";

export const Home = () => (
    
        <Link to="/">Home</Link>
    
);
export const Services = () => (
    <Fragment>
        <li><Link to="/services/endpointmonitoring">Endpoint Monitoring</Link></li>
        <li><Link to="/services/advancedantivirus">Advanced AntiVirus</Link></li>
        <li><Link to="/services/cloudbackup">Cloud Backup</Link></li>
        <li><Link to="/services/licensemanagement">License Management</Link></li>
        <li><Link to="/services/aiml">AI/ML/LLM</Link></li>
        <li><Link to="/services/itstaffing">IT Staffing</Link></li>
        <li><Link to="/services/networkmonitoring">Network Monitoring</Link></li>
        <li><Link to="/services/cybersecuritydocs">Cybersecurity Documents and Compliance</Link></li>
        <li><Link to="/services/itprojects">IT Projects</Link></li>
    </Fragment>
);
export const Page = () => (
    <Fragment>
        <li><Link to="/projects">Projects</Link></li>
        <li><Link to="/projects-details">Projects Details</Link></li>
        <li><Link to="/pricing">Pricing</Link></li>
        <li><Link to="/team">Team</Link></li>
        <li><Link to="/testimonial">Testimonial</Link></li>
        <li><Link to="/error">404 Page</Link></li>
    </Fragment>
);
export const News = () => (
    <Fragment>
        <li><Link to="/blog">Blog</Link></li>
        <li><Link to="/blog-details">Blog Details</Link></li>
    </Fragment>
);
