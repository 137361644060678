import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
import HeaderOne from '../Header/HeaderTwo';
import FooterOne from '../Footer/FooterThree';
import BreadCrumb  from '../BreadCrumb';
import { Link } from 'react-router-dom';
import contact from "../../assets/images/contactus.jpg";
import map from "../../assets/images/resource/map.jpg";
import { DiBlackberry } from 'react-icons/di';
import contactEmail from './contact.php'




const ContactForm = () => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      problem: "",
      city: "",
      device: "volvo", // default value
      priority: "p3"   // default value
    });

    const [showModal, setShowModal] = useState(false);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Split the name into first and last names
      const pieces = formData.name.split(" ");
      const lastName = pieces.slice(1).join(" ");
  
      // Create the data object to send
      const data = {
        first_name: pieces[0],
        last_name: lastName,
        email: formData.email,
        phone: formData.phone,
      };
  
      // Send data to the backend API
      fetch("https://justdoitservices.syncromsp.com/api/v1/leads?api_key=Tae84ddd0cff82be12-a80351ed91479e8c8c37f7baf4440c85", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("Success:", result);
          // Redirect to the thank you page
          setShowModal(true);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
  
    
  return (
    
    <>
        <HeaderOne></HeaderOne>
        <section className="contact__page p_relative see__pad">
            <div className="auto-container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-ms-12">
                        <div className="contact__left">
                            <figure>
                            <img src={contact} alt="" style={{paddingTop: '70px', objectFit:'cover', width: '100%', height: 'auto', filter: 'brightness(50%) contrast(150%)', borderRadius: '50px'}} />
                            </figure>
                            <h4>Contact Information</h4>
                            <div className="contact__info">
                                <div className="contact__block">
                                    <div className="icon">
                                        <i className="icon-19"></i>
                                    </div>
                                    <div className="contact__text">
                                        <Link to="tell:3363605277" style={{color: 'white', fontSize:'xx-large'}}>(336)360-5277</Link>
                                    </div>
                                </div>
                                <div className="contact__block">
                                    <div className="icon two">
                                        <i className="icon-20"></i>
                                    </div>
                                    <div className="contact__text">
                                        <Link to="mailto:support@justdoitservices.com" style={{color: 'white', fontSize:'x-large'}}>support@justdoitservices.com</Link> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <div className="contact__right">
                            <div className="form__title">
                                <div className="title__data">
                                    <div className="sub__title">
                                        <h4>Contact Us</h4>
                                    </div>
                                    <div className="title">
                                        <h2>Get a Free Quote</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="form-inner">
      <form onSubmit={handleSubmit} className="default-form">
        <div className="row clearfix">
          <div className="col-xl-6 form-group">
            <input
              type="text"
              name="name"
              placeholder="Your Name *"
              required
              value={formData.name}
              onChange={handleChange}
              style={{ color: 'white', fontSize: 'large' }}
            />
          </div>
          <div className="col-xl-6 col-lg-12 form-group">
            <input
              type="email"
              name="email"
              placeholder="Your Email *"
              required
              value={formData.email}
              onChange={handleChange}
              style={{ color: 'white', fontSize: 'large' }}
            />
          </div>
          <div className="col-xl-6 col-lg-12 form-group">
            <input
              type="text"
              name="phone"
              placeholder="Your Phone *"
              required
              value={formData.phone}
              onChange={handleChange}
              style={{ color: 'white', fontSize: 'large' }}
            />
          </div>
          
          <div className="col-xl-12 form-group message-btn btn-box">
            <button
              className="theme-btn theme-btn-one"
              type="submit"
              name="submit-form"
              style={{ color: 'white', fontSize: 'large' }}
            >
              Send your Contact 
            </button>
          </div>
        </div>
      </form>
       {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Thank You for Reaching Out!</h2>
            <p>
              We appreciate your submission. One of our Customer Account Managers will be in touch with you within the next 48 hours. We’re excited to assist you with your needs!
            </p>
            <button
              onClick={() => setShowModal(false)}
              className="modal-close-btn"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Modal Styling */}
      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .modal-content {
          background-color: #222;
          padding: 30px;
          border-radius: 10px;
          max-width: 500px;
          width: 100%;
          text-align: center;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
        }
        .modal-content h2 {
          color: #fff;
          margin-bottom: 20px;
          font-size: 24px;
        }
        .modal-content p {
          color: #bbb;
          font-size: 16px;
          margin-bottom: 20px;
        }
        .modal-close-btn {
          background-color: #ff4b2b;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .modal-close-btn:hover {
          background-color: #e14320;
        }
      `}</style>
    </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="google__map">
            
        </div>
        <FooterOne></FooterOne>
    </>
  );
};

export default ContactForm;
