import React from 'react';
import './service-modal.css';

const ServiceModal = ({ isOpen, onClose, service }) => {
  if (!isOpen || !service) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h2>{service.title}</h2>
        <p>{service.description}</p>
        {service.keyBenefits && (
          <>
            <h3>Key Benefits:</h3>
            <ul>
              {service.keyBenefits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>
          </>
        )}
        <a href="/contact" className="cta-button">Contact Us</a>
      </div>
    </div>
  );
};

export default ServiceModal;
