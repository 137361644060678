import React from "react";
import './detailsCSS.css';

const ArticleDetail = ({ article }) => {
  // If no article is provided (null), show "Article not found"
  if (!article) {
    return <h2>Article not found</h2>;
  }

  return (
    <div className="article-detail">
      <h1>{article.title}</h1>
      <img src={article.image} alt={article.title} />
      <p>{article.content}</p>
      <p>{article.content1}</p>
      <p>{article.content2}</p>
      <p>{article.content3}</p>
      <h2>Key Takeaways</h2> {/* Title for the bullet section */}
      <ul>
        {article.bullets.map((bullet, index) => (
          <li key={index}>{bullet}</li>
        ))}
      </ul>
    </div>
  );
};

export default ArticleDetail;
